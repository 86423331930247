import * as React from "react"
import PropTypes from "prop-types"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import {
    faArrowDown as faArrowDownReg,
    faArrowUp as faArrowUpReg,
    faArrowRight as faArrowRightReg,
    faBars as faBarsReg,
    faXmark,
    faCirclePlus,
    faCircleMinus,
    faClock,
    faMap
} from "@fortawesome/pro-regular-svg-icons"
import CookieConsent, { Cookies } from "react-cookie-consent"

library.add(
    fab,
    faEnvelope,
    faArrowDownReg,
    faArrowUpReg,
    faArrowRightReg,
    faBarsReg,
    faXmark,
    faCirclePlus,
    faCircleMinus,
    faCircleInfo,
    faClock,
    faMap
)

const Layout = ({ fullHeight, children }) => {
    return (
        <div className={` ${fullHeight ? "h-100" : ""}`}>
            {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
            <main className={`position-relative ${fullHeight ? "h-100" : ""}`}>{children}</main>
            {/* {/* <Footer /> */}
            <CookieConsent
                containerClasses="d-none"
                debug={false}
                acceptOnScroll={true}
                onAccept={() => {
                    Cookies.set("cookie-google", "true")
                    Cookies.set("cookie-facebook", "true")
                }}
                acceptOnScrollPercentage={0}
            ></CookieConsent>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
